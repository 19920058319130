<template>
  <div
    v-if="isFav"
    class="action-star"
    :class="{ loading: loading }"
    @click="RemoveFavorite()"
  >
    <d-icon icon="FaStar" size="is-normal" />
  </div>
  <div
    v-else
    class="action-star"
    :class="{ loading: loading }"
    @click="MakeFavorite()"
  >
    <d-icon icon="FaStarOutline" size="is-normal" />
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  props: {
    collection: {
      type: String,
      required: true,
    },
    mediaId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isFav() {
      return this.$store.state.user.favorites.some(
        (fav) => fav.media?.value.id == this.mediaId
      );
    },
  },
  methods: {
    async MakeFavorite() {
      if (this.loading) return;
      while (!this.$store.state.user.favoritesLoaded) {
        // console.log("Favs not loaded")
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      console.log("Chequeo no este en favoritos", this.mediaId, this.$store.state.user.favorites)
      if (this.$store.state.user.favorites.some(fav => fav.media?.value?.id ==  this.mediaId)) {
          console.log("Ya esta en favoritos")
          return;
      }
      console.log("MakeFavorite", this.collection, this.mediaId);
      // TODO prompt
      try {
        this.loading = true;
        const data = {
          createdBy: this.$store.state.user.profile.id,
          media: {
            relationTo: this.collection,
            value: this.mediaId,
          },
        };
        const res = await axios.post(
          `${process.env.VUE_APP_API_BASE}/api/favoritegallery`,
          data
        );
        // console.log(res)
        if (res.status == 201 || res.status == 200) {
          this.$store.commit("user/AddFavorite", res.data.doc);
        }
        this.$buefy.toast.open({
          message: "Imagen añadida a favoritos",
          type: "is-success",
        });
        this.$TrackEvent("Favorite Added",{
          'distinct_id': this.$store.state.user.profile.id,
          'mediaId': this.mediaId,
        })
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async RemoveFavorite() {
      if (this.loading) return;
      console.log("RemoveFavorite", this.mediaId);
      // TODO prompt
      try {
        this.loading = true;
        const favId = this.$store.state.user.favorites.find((fav) => fav.media?.value.id == this.mediaId).id;
        const res = await axios.delete(`${process.env.VUE_APP_API_BASE}/api/favoritegallery/${favId}`);
        if (res.status == 201 || res.status == 200) {
          this.$store.commit("user/RemoveFavorite", favId);
          this.$emit("removed");
          this.$buefy.toast.open({
            message: "Imagen removida de favoritos",
          });
          this.$TrackEvent("Favorite Removed",{
            'distinct_id': this.$store.state.user.profile.id,
            'mediaId': this.mediaId,
          })
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.loading {
  opacity: 0.5;
}
</style>
