<template>
  <div class="action-share" @click="UserClicked()" v-if="shareEnabled != 'disabled'" :style="{'opacity': loading ? 0.5 : 1}">
    <d-icon :icon="icon" size="is-normal" />
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  methods: {
    async UserClicked() {
      switch (this.shareEnabled) {
        case 'share-download':
          return this.ShareMedia();
        case 'download-only':
          return this.DownloadMedia();
      }
    },
    async ShareMedia() {
      if (navigator.share) {
        try {
          this.loading = true;
          // Fetch the image and convert to blob
          const response = await fetch(this.imageSrc);
          const blob = await response.blob();

          // Create a File object from the blob
          const filename = this.imageSrc.substring(
            this.imageSrc.lastIndexOf("/") + 1
          );
          const file = new File([blob], filename, { type: blob.type });
          console.log("sharing", filename, file);

          // Share using the Web Share API
          await navigator.share({
            title: this.shareTitle,
            text: this.shareText,
            files: [file],
          });

          return true;
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("User canceled sharing.");
            return false; // Do nothing if user cancels
          }
          console.log("Error sharing via Web Share API:", error);
        }finally{
          this.loading = false;
        }
      }

      console.log("Fallback for browsers that don't support Web Share API");
      await this.DownloadMedia();
    },
    async DownloadMedia() {
      try {
        this.loading = true;
        
        const response = await fetch(this.imageSrc);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        
        let filename = this.imageSrc.split('/').pop().split('#')[0].split('?')[0];
        if (!filename) {
          filename = 'download';
        }
        // Get extension from Content-Type if available
        const contentType = response.headers.get('content-type');
        if (!filename.includes('.') && contentType) {
          const ext = contentType.split('/')[1];
          filename += '.' + ext;
        }
        const anchor = document.createElement("a");
        anchor.href = blobUrl;
        anchor.download = filename;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(blobUrl);

        return true;
      } catch (error) {
        console.error("Download failed:", error);
        return false;
      }finally{
        this.loading = false;
      }
    }
  },
  computed: {
    eventShare() {
      return this.$store.state?.setup?.event?.share
    },
    shareEnabled() {
      if (!this.eventShare?.enabled) return 'share-download';
      return this.eventShare?.enabled;
    },
    shareTitle() {
      if(this.title) return this.title;
      return this.eventShare.shareTitle?.trim() || ''
    },
    shareText() {
      if(this.text) return this.text;
      return this.eventShare.shareText?.trim() || ''
    },
    icon() {
      switch (this.shareEnabled) {
        case 'share-download':
          return 'FaShare';
        case 'download-only':
          return 'FaDownload';
        default:
          return 'FaShare';
      }
    }
  },
};
</script>
