<template>
  <b-modal v-model="show" has-modal-card :can-cancel="false" full-screen>
    <div class="modal-card" v-if="imageIsProcessing">
      <div class="viewer-header p-2">
        <div class="is-flex is-align-items-center ml-2">
            <player-avatar
                :player="$store.state.user.profile" :scale="1.5" />
            <span class="ml-2 is-uppercase">{{ $store.state.user.profile.username }}</span>
        </div>
        <b-button
          type="is-ghost"
          size="is-medium"
          class="modal-close-btn"
          @click="Close()"
        >
          <d-icon icon="FaXmark" />
        </b-button>
      </div>
      <section class="modal-card-body">
        <section class="p-4 fullHeight has-background-light is-flex is-flex-direction-column is-align-items-center is-justify-content-center has-text-centered">
            <d-icon
            icon="FaHourglassHalf"
            size="is-medium"/>
            <h5 class="has-text-weight-bold is-size-5 text-balance">Tu archivo se está procesando...</h5>
            <p class="text-balance">En breve lo verás acá. ¡Espera un momento!.</p>
        </section>
      </section>
    </div>

    <div class="modal-card">
        <section class="modal-card-body p-0">
            <div class="image-container">
                <div class="viewer-header ml-2 p-3">
                    <template v-if="!currentImage">
                        <div class="ml-1 p-10 is-flex is-align-items-center is-flex-direction-row" style="width: 120px;">
                            <b-skeleton circle width="30px" height="30px"/>
                            <b-skeleton width="75px" height="20px" :animated="true"/>
                        </div>
                    </template>
                    <template v-else-if="currentImage?.createdBy">
                        <div class="is-flex is-align-items-center">
                            <player-avatar :player="currentImage.createdBy" :scale="1.5"/>
                            <span class="ml-2 is-uppercase">{{ currentImage.createdBy.username }}</span>
                        </div>
                    </template>
                    <div v-else>

                    </div>
                    <b-button type="is-ghost" size="is-medium" @click="Close()">
                        <d-icon icon="FaXmark" />
                    </b-button>
                </div>
                <div class="image-wrapper" ref="imageWrapper" @click="ImageWrapperClicked">
                    <template v-if="!currentImage">
                        <b-skeleton width="100%" height="400px" :animated="true"/>
                    </template>
                    <template v-else>
                        <template v-if="currentImage.media.relationTo == 'user-videos'">
                            <video :src="`${currentImage.media.value.url}#t=0.1`" preload="auto"
                                disableRemotePlayback muted playsinline autoplay loop no-controls
                                class="preview-image" />
                        </template>
                        <template v-if="currentImage.media.relationTo == 'user-images'">
                            <img :src="currentImage.media.value.sizes.high.url" :alt="currentImage.title"
                                class="preview-image" />
                        </template>
                    </template>
                </div>
                <div class="p-3">
                    <div class="is-flex is-justify-content-space-between is-align-flex-start">
                        <div class="viewer-actions">
                            <template v-if="!currentImage">
                                <b-skeleton width="120px" height="20px" :animated="true"></b-skeleton>
                            </template>
                            <template v-else>
                                <StarFavoriteMedia ref="StarFavorite" :collection="currentImage.media.relationTo"
                                    :mediaId="currentImage.media.value.id" />
                                <ShareMedia :imageSrc="currentImage.media.value.url"
                                    :text="currentImage.description" />
                                <div class="action-delete" v-if="isImageOwner" @click="Delete()">
                                    <d-icon icon="FaTrash" size="is-normal"/>
                                </div>
                            </template>
                        </div>
                        <div class="is-size-7" v-if="currentImage">{{ formatDate(currentImage.createdAt) }}</div>
                    </div>
                    <div v-if="currentImage?.description" class="description mt-2">
                        {{ currentImage.description }}
                    </div>
                </div>
            </div>
        </section>
    </div>
    </b-modal>
</template>

<script>
import PlayerAvatar from "@/components/PlayerAvatar.vue";
import ShareMedia from "@/components/ShareMedia.vue";
import StarFavoriteMedia from "@/components/StarFavoriteMedia.vue";
import { formatLongDate } from "@/utils/utils.js";
import axios from "axios";

export default {
    components: {
        PlayerAvatar,
        ShareMedia,
        StarFavoriteMedia,
    },
    data() {
        return {
            show: false,
            currentId: null,
            isImageOwner: null,
            currentImage: null,

            imageIsProcessing: false,
        };
    },

    computed: {
        UrlId() {
            return this.$route.params.id;
        },
        MediaSrc() {
            if (!this.currentImage) return null
            return this.currentImage?.sizes?.high?.url || this.currentImage.url;
        },
        media() {
            return this.$parent.media
        },
        allMedia() {
            return this.$parent.allMedia
        }
    },
    watch: {
        async UrlId() {
            this.currentId = this.UrlId;
            this.currentImage = this.media.find(m => m.id == this.currentId)
            if (this.currentImage) {
                this.show = true
            } else {
                while (this.allMedia == null) {
                    // Espero a que los favorites esten cargados
                    await new Promise(resolve => setTimeout(resolve, 100));
                }
                this.currentImage = this.allMedia.find(m => m.id == this.currentId)
                this.show = true
            }
            console.log("Current Image", this.currentImage)
            this.isImageOwner = this.currentImage.createdBy?.id === this.$store.state.user.profile.id
            this.HandleActionFav();
        }
    },
    methods: {
        GetWrappedIndex(currentIndex, step, arrayLength) {
            return (currentIndex + step + arrayLength) % arrayLength;
        },
        async ImageWrapperClicked(event) {
            // Wait for all allMedia to be loaded
            while (!this.allMedia?.length) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            // Check if element was clicked on the right or left part
            const rect = this.$refs.imageWrapper.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const width = rect.right - rect.left;

            let urlToRedirect = null;
            const currentIndex = this.allMedia.findIndex(m => m.id == this.currentId)
            let desiredIndex = null;
            // ME fijo en que tercio de la imagen se hizo click
            if (x < width / 3) {
                // Left
                desiredIndex = this.GetWrappedIndex(currentIndex, -1, this.allMedia.length)
            } else {
                // Right
                desiredIndex = this.GetWrappedIndex(currentIndex, +1, this.allMedia.length)
            }
            const desiredImg = this.allMedia[desiredIndex]
            urlToRedirect = `/photofeed/${desiredImg.id}`

            if (this.currentId === desiredImg.id) return
            this.$router.push(urlToRedirect);

        },
        Delete() {
            this.$buefy.dialog.confirm({
                message: "¿Estás seguro de que querés eliminar esta imagen?",
                confirmText: "Sí, eliminar",
                cancelText: "Cancelar",
                onConfirm: () => this.HandleDelete()
            });
        },
        async HandleDelete() {
            try {
                const res = await axios.delete(`${process.env.VUE_APP_API_BASE}/api/photofeed/${this.currentId}`)
                if (res.status == 200) {
                    this.$buefy.toast.open({
                        message: "Imagen eliminada",
                        type: "is-success"
                    })
                    // eliminar de cache y display
                    this.$parent.removeImage(this.currentId)
                    this.$router.push('/photofeed')
                }
            } catch (e) {
                console.error(e)
                this.$buefy.toast.open({
                    message: "Error al eliminar la imagen",
                    type: "is-danger"
                })
            }
        },
        Close() {
            this.$router.push('/photofeed')
        },
        async HandleActionFav() {
            // Espero a que los favorites esten cargados
            while (!this.$store.state.user.favoritesLoaded) {
                // console.log("Favs not loaded")
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            if (this.$route.query.action && this.$route.query.action == "fav") {
                // Chequeo que esta imagen no este en favoritos

                if (this.$store.state.user.favorites.find(fav => fav.media?.value?.id == this.currentId)) {
                    console.log("Ya esta en favoritos")
                    return;
                }

                // Espero que el componente este montado
                while (!this.$refs.StarFavorite) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }
                // Agrego a favoritos
                console.log(this.$refs.StarFavorite)
                this.$refs.StarFavorite.MakeFavorite()
            }
        },
        async FetchDocByKey() {
            if (!this.$route.params.key) return
            console.log("Fetching doc by key", this.$route.params.key)
            const keyResponse = await fetch(`${process.env.VUE_APP_API_BASE}/api/photofeed/?where[accesskey][equals]=${this.$route.params.key}`)
            const keyDoc = await keyResponse.json()
            if (keyDoc.totalDocs == 0) {
                this.imageIsProcessing = true
                this.show = true;
                this.SaveFavoriteByAccessKey();
                setTimeout(() => {
                    this.FetchDocByKey();
                }, 5000);
            } else {
                let image = keyDoc.docs[0];
                // Espero a que los photofeed esten cargados
                while (this.allMedia == null) {
                    await new Promise(resolve => setTimeout(resolve, 100));
                }
                console.log("*** Image", image)
                if(!image.createdBy){    
                    console.log("actualizando createby")
                    image.createdBy = this.$store.state.user.profile;
                    // sin await para no trabar el proceso. no hace falta la respuesta
                    axios.put(`${process.env.VUE_APP_API_BASE}/api/photofeed/${image.id}`, {
                        createdBy: this.$store.state.user.profile.id
                    })
                }
                this.$store.dispatch("event/AgregarMediaPhotofeed", image);
                this.imageIsProcessing = false
                this.currentId = image.id;
                let query = this.$route.query;
                query.action = "fav";
                console.log("Router replace url")
                this.$router.replace({ path: `/photofeed/${this.currentId}`, query })
                this.HandleActionFav();
                // La agrego a la lista visible del photofeed
                this.$parent.AgregarImagen(image);
            }
        },
        async SaveFavoriteByAccessKey() {
            // Primero chequeo si no fue creado aún
            // Wait for id
            while (!this.$store.state.user.profile.id) {
                await new Promise(resolve => setTimeout(resolve, 100));
            }
            const exists = await axios.get(`${process.env.VUE_APP_API_BASE}/api/favoriteGallery?where[and][0][accesskey][equals]=${this.$route.params.key}&where[and][1][createdBy][equals]=${this.$store.state.user.profile.id}`)
            console.log("Save Fav By Access key", { exists })
            if (exists.data.totalDocs == 0) {
                try {
                    // Creo el favorito
                    const res = await axios.post(`${process.env.VUE_APP_API_BASE}/api/favoriteGallery`, {
                        accesskey: this.$route.params.key,
                        createdBy: this.$store.state.user.profile.id,
                        mediaPending: true,
                    })
                    console.log("Created favorite", res.data)
                    this.$buefy.toast.open({
                        message: "Imagen añadida a favoritos. Cuando esté disponible, aparecerá en Mis Archivos.",
                        type: "is-success"
                    })
                    // Update favs
                    this.$store.dispatch('user/LoadFavorites')
                } catch (e) {
                    console.error(e)
                }
            } else {
                // console.log("Imagen ya existe en favoritos")
            }
        },
        formatDate(date) {
            return formatLongDate(date, this.$i18n.locale);
        }
    },
    async mounted() {
        if (this.$route.params.key) {
            if(this.$route.params.key == "duplicate"){
                this.$router.push('/duplicate')
            }else{
                // La app de firmas me manda aca desde QR
                // Porque no existia ID al momento de generar la imagen
                this.FetchDocByKey();
                this.$TrackEvent("Opened Photofeed QR", {
                    distinct_id: this.$store.state.user.profile.id,
                    key: this.$route.params.key
                });
            }
        } else {
            this.currentId = this.UrlId;
            this.currentImage = this.media.find(m => m.id == this.currentId)
            if (this.currentImage) {
                this.show = true
            } else {
                // Fetch image
                const response = await fetch(`${process.env.VUE_APP_API_BASE}/api/photofeed/${this.currentId}`)
                const image = await response.json()
                this.currentImage = image
                this.show = true
            }
            this.isImageOwner = this.currentImage.createdBy?.id === this.$store.state.user.profile.id
            this.HandleActionFav();
        }
    }
}
</script>

<style scoped lang="scss">
.modal-card {
    display: flex;
    flex-direction: column;
    height: 100%;

    & :depth(.username) {
        background: #bdbdbd;
    }
}

.modal-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;
    overflow-x: hidden;

  .modal-close-btn {
    margin-top: -0.5rem;
    margin-right: -0.5rem;
  }

  section.is-flex {
    gap: 1rem;

    h5 {
        line-height: 1;
    }
  }
}

.image-wrapper {
    min-height: 0;
    position: relative;
    height: 70dvh;
    background-color: #F5F5F5;
    display: flex;
    justify-content: center;
    padding-inline: 1rem;
}

.preview-image {
  height: 100%;
  object-fit: contain;
  min-height: 250px;
  max-height: 70dvh;
  border-radius: 4px;
}

.viewer-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewer-actions {
    display: flex;
    gap: .8rem;

    .action-share {
        flex-grow: 1;
    }
}
</style>
